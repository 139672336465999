import { defineComponent } from "vue";

export default defineComponent({
  name: "Key Sector",
  props: {
    name: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  }
});
